<template>

  <v-layout>


<!--    <nav-left/>-->

<!--  <v-navigation-drawer-->
<!--      permanent-->
<!--  >-->
<!--    <nav-left/>-->
<!--  </v-navigation-drawer>-->

  <v-app-bar>

    <v-row justify="start" class="ml-2">
      <v-col>
        <localized-link to="Home">
          <v-img class="" src="@/assets/p4mLogo.jpg" width="50px" />
        </localized-link>
      </v-col>
    </v-row>

    <div justify="start">

<!--      <localized-link to="Dashboard">-->
<!--        <v-btn variant="plain">{{ $t("nav.dashboard") }}</v-btn>-->
<!--      </localized-link>-->

    </div>


<!--    <v-spacer></v-spacer>-->
<!--    <div justify="end">&nbsp;|&nbsp;</div>-->

<!--    <v-btn variant="plain">-->
<!--      <localized-link to="Login">{{ $t("nav.profile") }}</localized-link>-->
<!--    </v-btn>-->

  </v-app-bar>


  <v-main>
    <nav-left/>
    <router-view />
  </v-main>

  <v-footer>
    <v-col class="text-center mt-4 text-disabled" cols="12">
      {{ new Date().getFullYear() }} — <strong>PriceForMe.com</strong>
    </v-col>
  </v-footer>

  </v-layout>

</template>

<script>

import LocalizedLink from "@/components/LocalizedLink";
import NavLeft from "@/components/NavLeft";
export default {
  name: "AppCabinet",
  components: {NavLeft, LocalizedLink}
}
</script>

<style scoped>

</style>