import {supportedLocalesInclude} from "@/util/i18n/supported-locales";
import getBrowserLocale from "@/util/i18n/get-browser-locale";


export function getStartingLocale() {

  let urlLocale = window.location.pathname.split('/')[1]
  urlLocale = supportedLocalesInclude(urlLocale) ? urlLocale : ''

  let browserLocale = getBrowserLocale({ countryCodeOnly: true })
  browserLocale = supportedLocalesInclude(browserLocale) ? browserLocale : ''

  return urlLocale || browserLocale || process.env.VUE_APP_I18N_LOCALE || "en"
}
