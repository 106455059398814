<template>

  <v-app-bar>

      <v-row justify="start" class="ml-2">
        <v-col>
          <localized-link to="Home">
            <v-img class="" src="@/assets/Logo-PriceForMe-light.png" width="150px" />
          </localized-link>
        </v-col>
      </v-row>


    <div justify="start">
<!--        <v-btn variant="plain">-->
<!--          <localized-link to="About">{{ $t("nav.about") }}</localized-link>-->
<!--        </v-btn>-->

<!--        <localized-link to="Prices">-->
<!--          <v-btn variant="plain">{{ $t("nav.prices") }}</v-btn>-->
<!--        </localized-link>-->


    </div>

    <v-spacer></v-spacer>

<!--    <localized-link to="Dashboard">-->
<!--      <v-btn variant="plain">{{ $t("nav.dashboard") }}</v-btn>-->
<!--    </localized-link>-->


    <locale-switcher />

<!--    <div justify="end">&nbsp;|&nbsp;</div>-->
    <v-divider vertical inset/>

    <v-btn variant="plain">
      <localized-link to="SignIn">{{ $t("btn.sign_in") }}</localized-link>
    </v-btn>
  </v-app-bar>

  <v-main>
    <router-view />
  </v-main>


  <!--- FOOTER --->
  <v-footer class="bg-grey-lighten-1">
    <v-row justify="center" no-gutters>
      <v-btn
          v-for="link in links"
          :key="link"

          variant="text"
          class="mx-2"
          rounded="xl"
      >
        <localized-link :to="link" >{{ $t("nav." + link.toLowerCase()) }}</localized-link>

      </v-btn>
      <v-col class="text-center mt-4" cols="12">
        {{ new Date().getFullYear() }} — <strong>PriceForMe</strong>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import LocalizedLink from "@/components/LocalizedLink";
import LocaleSwitcher from "@/components/LocaleSwitcher";

export default {
  name: "AppHome",
  components: {LocaleSwitcher, LocalizedLink},
  data: () => ({
    links: [
      // 'About',
      // 'Prices',
      'Privacy',
      'Terms',
    ],
  }),
}
</script>

<style scoped>

</style>