export default {
  "app": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PriceForMe.com"])},
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A simple service for automating work with suppliers and marketplaces for online stores."])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prices"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])}
  },
  "btn": {
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "try_it_for_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try it for free"])},
    "sign_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in"])},
    "sign_in_google": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign in with Google"])},
    "remind_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remind password"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot password"])}
  },
  "txt": {
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])}
  },
  "about": {
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About page!"])}
  },
  "user_greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hello, ", _interpolate(_named("name"))])}
}