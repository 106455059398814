import { createRouter, createWebHistory } from 'vue-router'

import RootBase from "@/components/RootBase.vue"
// import HomeView from '../components/HomeView.vue'

import i18n from "@/i18n"
// import i18n, { loadLocaleMessagesAsync } from "@/i18n"
// import {
//   setDocumentDirectionPerLocale,
//   setDocumentLang,
//   setDocumentTitle
// } from "@/util/i18n/document"

// let { locale } = i18n

import getBrowserLocale from '@/util/i18n/get-browser-locale'
import {supportedLocalesInclude} from "@/util/i18n/supported-locales";
import { getStartingLocale} from "@/util/i18n/starting-locales"

// locale = locale || getBrowserLocale({countryCodeOnly: true})

// console.log('...............i18n.locale:', i18n.locale, )
// document.querySelector('html').setAttribute('lang', i18n.locale)

const routes = [
  {
    path: "/",
    redirect: '/' + i18n.locale
  },
  {
    path: "/:locale",
    name: RootBase,
    component: RootBase,
    beforeEnter(to, from){
      // correction bad locale
      let locale = to.params.locale
      if(!supportedLocalesInclude(locale)){
        locale = getStartingLocale()
        to.params.locale = locale
        i18n.locale = locale
        router.push(`/${locale}`)
      }

    },
    children: [
      {
        path: '',
        name: 'Home',
        component: load('Home')
      },
      {
        path: 'about',
        name: 'About',
        component: load('About')
      },
      {
        path: 'prices',
        name: 'Prices',
        component: load('Prices')
      },

      {
        path: 'auth',
        name: 'Auth',
        children: [
          {
            path: 'sign-in',
            name: 'SignIn',
            component: load('auth/SignIn')
          },
          {
            path: 'forgot-password',
            name: 'ForgotPassword',
            component: load('auth/ForgotPassword')
          },
          {
            path: 'sign-in-success',
            name: 'SignInSuccess',
            component: load('auth/SignInSuccess')
          },
          {
            path: 'sign-in-error',
            name: 'SignInError',
            component: load('auth/SignInError')
          },
        ]
      },

      {
        path: 'login',
        name: 'Login',
        component: load('auth/Login')
      },

      {
        path: 'login-success',
        name: 'LoginSuccess',
        component: load('auth/LoginSuccess')
      },


      {
        path: 'privacy',
        name: 'Privacy',
        component: load('Privacy')
      },
      {
        path: 'terms',
        name: 'Terms',
        component: load('Terms')
      },


      {
        path: 'cabinet',
        name: 'AppCabinet',
        // component: load('AppCabinet'),
        children: [
          {
            path: 'dasboard',
            name: 'Dashboard',
            component: load('cabinet/Dashboard')
          },
        ]
      },
    ]
  },
  //
  {
    path: "/:pathMatch(.*)*",
    name: '404',
    component: load('404')
  },

]


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes
})


router.beforeEach((to, from, next) => {

  let locale = to.params.locale

  if (locale !== from.params.locale) {
    document.querySelector('html').setAttribute('lang', locale)
    // console.log('...сменили локаль')
  }

  next()

})


function load(component) {
  // '@' is aliased to src/components
  return () => import(`@/views/${component}.vue`)
}

export default router
