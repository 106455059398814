export default {
  "app": {
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простий сервіс для автоматизації роботи з постачальниками та маркетплейсами для інтернет-магазинів."])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Головна"])}
  },
  "about": {
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О проекте"])}
  }
}