<template>

    <v-btn variant="plain"
        id="menu-activator"
        style="color: inherit;"
    >
<!--      <v-icon left>mdi-translate</v-icon>-->
      &nbsp;{{ activeLang }}
      <v-icon small right>mdi-menu-down</v-icon>
    </v-btn>

    <v-menu activator="#menu-activator">
      <v-list>
        <v-list-item
            v-for="(lang, index) in locales"
            :key="index"
            :value="index"
            @click="changeLocale(lang.code)"
        >
          <v-list-item-title>{{lang.code.toUpperCase()}} - {{ lang.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

</template>

<script>
import { getSupportedLocales } from "@/util/i18n/supported-locales"
import { getStartingLocale } from "@/util/i18n/starting-locales";

export default {

  data: () => ({
    locales: getSupportedLocales(),
    activeLang: getStartingLocale(),
  }),

  methods: {

    changeLocale(locale) {

      this.activeLang = locale;
      this.$i18n.locale = locale

      let query = JSON.parse(JSON.stringify(this.$route.query))
      let params = JSON.parse(JSON.stringify(this.$route.params))
      params.locale = locale

      this.$router.replace({ params, query })
    },

  }
}
</script>
