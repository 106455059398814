<template>
  <h3>Loading...</h3>
</template>

<script>
export default {
  name: "LoadWaiting"
}
</script>

<style scoped>

</style>