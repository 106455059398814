<template>
  <v-app>

    <load-waiting v-if="isLoading"/>
    <AppCabinet v-else-if="isCabinet()"/>
    <AppHome v-else/>

  </v-app>

</template>

<script>
import AppCabinet from "@/views/AppCabinet";
import AppHome from "@/views/AppHome";
import LoadWaiting from "@/views/LoadWaiting";

export default {
  data: () => ({
    isLoading: false,
  }),
  components: {LoadWaiting, AppHome, AppCabinet},
  methods: {
    isCabinet() {
      return this.$route.path.includes('/cabinet/')
    },
  }
}
</script>


<style>

/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*}*/

/*nav {*/
/*  padding: 30px;*/
/*}*/

/*nav a {*/
/*  font-weight: bold;*/
/*  color: #2c3e50;*/
/*}*/

/*nav a.router-link-exact-active {*/
/*  color: #42b983;*/
/*}*/


/*body {*/
/*  margin: 0;*/
/*}*/
/*#app {*/
/*  font-family: "Avenir", Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  color: #2c3e50;*/
/*}*/
/*#app .container {*/
/*  padding: 1rem;*/
/*}*/

</style>
