export default {
  "app": {
    "sub_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Простой сервис для автоматизации работы с поставщиками и маркетплейсами для интернет-магазинов."])}
  },
  "nav": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Главная"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О проекте"])}
  },
  "about": {
    "h1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О проекте"])}
  },
  "user_greeting": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Привет, ", _interpolate(_named("name"))])}
}