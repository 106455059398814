<template>
  <router-link
      :to="getTo()"
      style="text-decoration: none; color: inherit;"
  ><slot /></router-link>
</template>

<script>
export default {
  props: ["to"],
  methods: {
    getTo() {

      let query = JSON.parse(JSON.stringify(this.$route.query))
      let params = JSON.parse(JSON.stringify(this.$route.params))
      params.locale = this.$i18n.locale

      return { name: this.to, params, query }

    }
  }
}
</script>
