<template>
<!--  <v-card>-->
<!--    <v-layout>-->
      <v-navigation-drawer
          v-model="drawer"
          :rail="rail"
          permanent
          @click="rail = false"
      >
        <v-list-item
            prepend-avatar="https://randomuser.me/api/portraits/men/85.jpg"
            title="John Leider"
            nav
        >
          <template v-slot:append>
            <v-btn
                variant="text"
                icon="mdi-chevron-left"
                @click.stop="rail = !rail"
            ></v-btn>
          </template>
        </v-list-item>

        <v-divider></v-divider>

        <v-list density="compact" nav>
          <v-list-item prepend-icon="mdi-monitor-dashboard" title="Dashboard" value="dashboard"></v-list-item>
          <v-list-item prepend-icon="mdi-store-outline" title="Stores" value="store"></v-list-item>
          <v-list-item prepend-icon="mdi-truck-fast-outline" title="Suppliers" value="suppliers"></v-list-item>
<!--          <v-list-item prepend-icon="mdi-account" title="Profile" value="account"></v-list-item>-->
        </v-list>
      </v-navigation-drawer>


</template>

<script>
export default {
  name: "NavLeft",
  data () {
    return {
      drawer: true,
      items: [
        { title: 'My shops', icon: 'mdi-home-city' },
        { title: 'My suppliers', icon: 'mdi-account' },
        { title: 'Profile', icon: 'mdi-account-group-outline' },
      ],
      rail: false,
    }
  },
}
</script>

<style scoped>

</style>