import { createApp } from 'vue'
import { createStore } from 'vuex' // https://vuex.vuejs.org/guide/state.html#getting-vuex-state-into-vue-components

// Vuetify
import 'vuetify/styles'
// import './scss/main.scss';

import { createVuetify } from 'vuetify'
import { aliases, mdi } from 'vuetify/lib/iconsets/mdi'


const vuetify = createVuetify({
  ssr: true,
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    }
  },
  theme: {
    defaultTheme: 'light', // 'dark' // Changing theme -- https://next.vuetifyjs.com/en/features/theme/#changing-theme
  },
})


import App from './App.vue'
import router from './router'
import i18n from './i18n'

// Create a new store instance.
const store = createStore({
  state () {
    return {
      count: 0
    }
  },
  mutations: {
    increment (state) {
      state.count++
    }
  }
})


import gAuthPlugin from 'vue3-google-oauth2';
let gauthClientId = "768834812579-ivi0oopbkqe05cg6t41p83t7gteekut6.apps.googleusercontent.com";


createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  // .use(VueSidebarMenu)
  .use(vuetify)
  .use(gAuthPlugin, { clientId: gauthClientId, scope: 'email', prompt: 'consent', fetch_basic_profile: false })
  .mount('#app')


